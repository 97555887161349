import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { MdSchool } from "react-icons/md";
import {
  FaJava,
  FaPython,
  FaSchool,
} from "react-icons/fa";
import { SiDjango } from "react-icons/si";
import { GiStarSwirl } from "react-icons/gi";

import "./Timeline.css";
const Timeline = ({ data }) => {
  if (!data) return null;

  return (
    <section id="timeline">
      <VerticalTimeline animate="true">

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          date={data.graduation.date}
          iconStyle={{ background: "rgb(255,223,0)", color: "#fff" }}
          icon={<MdSchool />}
      >
        <h3 className="vertical-timeline-element-title">
          {data.graduation.title}
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          {data.graduation.subTitle}
        </h4>
      </VerticalTimelineElement>

        <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            date={data.uni.date}
            iconStyle={{ background: "rgb(255,223,0)", color: "#fff" }}
            icon={<FaSchool />}
        >
          <h3 className="vertical-timeline-element-title">{data.uni.title}</h3>
          <h4 className="vertical-timeline-element-subtitle">
            {data.uni.subTitle}
          </h4>
          <p>{data.uni.content}</p>
        </VerticalTimelineElement>



        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date={data.java.date}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<FaJava />}
        >
          <h3 className="vertical-timeline-element-title">{data.java.title}</h3>
          <h4 className="vertical-timeline-element-subtitle">
            {data.java.subTitle}
          </h4>
          <p>{data.java.content}</p>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={data.python.date}
          iconStyle={{ background: "rgb(75, 139, 190)", color: "#fff" }}
          icon={<FaPython />}
        >
          <h3 className="vertical-timeline-element-title">
            {data.python.title}
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            {data.python.subTitle}
          </h4>
          <p>{data.python.content}</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={data.django.date}
          iconStyle={{ background: "rgb(12, 75, 51)", color: "#fff" }}
          icon={<SiDjango />}
        >
          <h3 className="vertical-timeline-element-title">
            {data.django.title}
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            {data.django.subTitle}
          </h4>
          <p>{data.django.content}</p>
        </VerticalTimelineElement>





        <VerticalTimelineElement
          iconStyle={{ background: "rgb(0, 0, 63)", color: "#fff" }}
          icon={<GiStarSwirl />}
        />
      </VerticalTimeline>
    </section>
  );
};

export default Timeline;
