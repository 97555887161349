import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    // const name = this.props.data.name;
    // const street = this.props.data.address.street;
    // const city = this.props.data.address.city;
    // const state = this.props.data.address.state;
    // const zip = this.props.data.address.zip;
    // const phone = this.props.data.phone;
    const message = this.props.data.contactmessage;

    const contactHeader = this.props.data.contact.contactHeader;
    /*
    const contactNameLabel = this.props.data.contact.contactNameLabel;
    const contactEmailLabel = this.props.data.contact.contactEmailLabel;
    const contactSubjectLabel = this.props.data.contact.contactSubjectLabel;
    const contactMessageLabel = this.props.data.contact.contactMessageLabel;
    const contactSubmitLabel = this.props.data.contact.contactSubmitLabel;
    const contactErrorMessage = this.props.data.contact.contactErrorMessage;
    const contacSuccessMessage = this.props.data.contact.contacSuccessMessage;
    */

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>{contactHeader}</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{message}</p>
              <h2 >
                <a href="mailto:jhohoppe02@gmail.com" className="mailLink">
                  jhohoppe@gmail.com
                </a>
              </h2>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
