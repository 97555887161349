import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {

  render() {
    if (!this.props.data) return null;

    // const project = this.props.data.project;
    const github = this.props.data.github;
    // const name = this.props.data.name;
    const description = this.props.data.description;
    
    const homeTab = this.props.data.tabs.home
    const aboutTab = this.props.data.tabs.about
    const timelineTab = this.props.data.tabs.timeline
    // const skillsTab = this.props.data.tabs.skills
    const contactTab = this.props.data.tabs.contact

    // const portfolioTab = this.props.data.tabs.portfolio

    const contactLabel = this.props.data.contactLabel


    const showNav = this.props.data.tabs.showNav
    const hideNav = this.props.data.tabs.hideNav

    // const colors = ["#123456", "#654321"]



    return (
      <header id="home">
        <ParticlesBg type="square" num={13} bg={true} />

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            { showNav }
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            { hideNav }
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                {homeTab}
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                {aboutTab}
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#timeline">
                {timelineTab}
              </a>
            </li>

   {/*         <li>
              <a className="smoothscroll" href="#portfolio">
                {portfolioTab}
              </a>
            </li>*/}

{/*            <li>
              <a className="smoothscroll" href="#skills">
                {skillsTab}
              </a>
            </li>*/}

            <li>
              <a className="smoothscroll" href="#contact">
                {contactTab}
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline">
                <span className="highlight">Jo</span>hannes
                <span> </span>
                <span className="highlight">Hop</span>pe
              </h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}.</h3>
            </Fade>
            <hr />
            <Fade bottom duration={2000}>
              <ul className="social">
                <a href="#contact" className="button btn project-btn smoothscroll">
                  <i className="fa fa-envelope"></i>{contactLabel}
                </a>
                <a href={github} className="button btn github-btn">
                  <i className="fa fa-github"></i>Github
                </a>
              </ul>
            </Fade>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
